import { useState } from "react";

import "./Survey.css";
import { RouteEntry } from "../Tables";
import LevelTag from "../Components/LevelTag";
import {
  NextButton,
  BackButton,
  HelpButton,
  SpanishButton,
} from "../Components/Button";
import RadioButton from "../Components/RadioButton";
import HelpPopup from "../HelpPopup/HelpPopup";

import ProgressBar from "@ramonak/react-progress-bar";
import { useGlobalContext } from "../GlobalContext";

function SurveyQuestion(props: {
  surveyType: number;
  questionNumber: number;
  totalQuestions: number;
  route1: RouteEntry;
  route2: RouteEntry;
  next: () => void;
  backDisabled: boolean;
  back: () => void;
  setResponse: (
    surveyType: number,
    questionNumber: number,
    response: string
  ) => void;
}) {
  const [selected, setSelected] = useState<number>(0);
  const [displayHelpButton, setDisplayHelpButton] = useState(false);
  const { isSpanish } = useGlobalContext();

  const onSelection = (id: number) => {
    setSelected(id);
    const response = id === 1 ? props.route1.name : props.route2.name;
    props.setResponse(props.surveyType, props.questionNumber, response);
    // TODO: Remove this if you want MANUAL navigation.
    // props.next();
  };

  const route1 = props.route1;
  const route2 = props.route2;

  return (
    <>
      {displayHelpButton ? (
        <>
          <HelpPopup setDisplayHelpButton={setDisplayHelpButton} />
        </>
      ) : (
        <div className="landing-container">
          <div style={{ width: "85%", height: "100%", marginTop: "3%" }}>
            <table className="route-table" style={{ marginTop: "3%" }}>
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "4%",
                      }}
                    >
                      <h2 style={{ marginRight: "5%" }}>
                        {props.questionNumber}/{props.totalQuestions}
                      </h2>
                      <div style={{ flexGrow: 1 }}>
                        <ProgressBar
                          completed={
                            (props.questionNumber / props.totalQuestions) * 100
                          }
                          width="75%"
                          height="20px"
                          isLabelVisible={false}
                          labelSize="24px"
                          baseBgColor="#E4E3E4"
                          bgColor="#4860EE"
                        />
                      </div>
                      <div>
                        <HelpButton
                          buttonAction={() => setDisplayHelpButton(true)}
                        />
                        <SpanishButton
                          style={{ marginRight: "7%", marginTop: "6.5%" }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  {props.surveyType === 0 && (
                    <td colSpan={3} className="purposeful-walks-row">
                      {!isSpanish
                        ? "Walking as a transportation method"
                        : "Caminar como método de transporte"}
                    </td>
                  )}
                  {props.surveyType === 1 && (
                    <td colSpan={3} className="recreational-walks-row">
                      {!isSpanish
                        ? "Recreational Walks"
                        : "Caminar por recreación, deporte, ejercicio o esparcimiento"}
                    </td>
                  )}
                </tr>
                <tr>
                  <td></td>
                  <td className="path-name">{route1.name}</td>
                  <td className="path-name">{route2.name}</td>
                </tr>
                <tr>
                  <th className="col1">
                    {!isSpanish
                      ? "Walk Time (min)"
                      : "Tiempo de Caminata (min)"}
                  </th>
                  <td>{route1.getWalkTimeString()}</td>
                  <td>{route2.getWalkTimeString()}</td>
                </tr>
                <tr>
                  <th className="col1">
                    {!isSpanish
                      ? "Sidewalk Condition"
                      : "Condición de la Acera"}
                  </th>
                  <td>
                    <LevelTag
                      text={route1.getSidewalkQualityString(isSpanish)}
                      level={route1.sidewalkQuality}
                    />
                  </td>
                  <td>
                    <LevelTag
                      text={route2.getSidewalkQualityString(isSpanish)}
                      level={route2.sidewalkQuality}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="col1">
                    {!isSpanish
                      ? "Crime Safety"
                      : "Seguridad en Relación al Crimen"}
                  </th>
                  <td>
                    <LevelTag
                      text={route1.getCrimeSafetyString(isSpanish)}
                      level={route1.crimeSafety}
                    />
                  </td>
                  <td>
                    <LevelTag
                      text={route2.getCrimeSafetyString(isSpanish)}
                      level={route2.crimeSafety}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="col1">
                    {!isSpanish ? "Traffic Safety" : "Seguridad Vial"}
                  </th>
                  <td>
                    <LevelTag
                      text={route1.getTrafficSafetyString(isSpanish)}
                      level={route1.trafficSafety}
                    />
                  </td>
                  <td>
                    <LevelTag
                      text={route2.getTrafficSafetyString(isSpanish)}
                      level={route2.trafficSafety}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="col1">
                    {!isSpanish ? "Thermal Comfort" : "Comodidad Térmica"}
                  </th>
                  <td>
                    <LevelTag
                      text={route1.getThermalComfortString(isSpanish)}
                      level={route1.thermalComfort}
                    />
                  </td>
                  <td>
                    <LevelTag
                      text={route2.getThermalComfortString(isSpanish)}
                      level={route2.thermalComfort}
                    />
                  </td>
                </tr>
                <tr style={{ height: "20px", border: "none" }}>
                  <td colSpan={3}></td>
                </tr>
                {props.surveyType === 0 && (
                  <tr>
                    <th
                      className="answer-col"
                      style={{ background: "#C8DAFB" }}
                    >
                      {!isSpanish
                        ? "Which path do you prefer?"
                        : "¿Qué camino prefiere?"}
                    </th>
                    <td style={{ background: "#EDF2FD" }}>
                      <RadioButton
                        selected={selected === 1}
                        onClick={() => onSelection(1)}
                      />
                    </td>
                    <td style={{ background: "#EDF2FD" }}>
                      <RadioButton
                        selected={selected === 2}
                        onClick={() => onSelection(2)}
                      />
                    </td>
                  </tr>
                )}
                {props.surveyType === 1 && (
                  <tr style={{ padding: "5%" }}>
                    <th
                      className="answer-col"
                      style={{ background: "#D3E7C5" }}
                    >
                      {!isSpanish
                        ? "Which path do you prefer?"
                        : "¿Qué camino prefiere?"}
                    </th>
                    <td style={{ background: "#E8F4E3" }}>
                      <RadioButton
                        selected={selected === 1}
                        onClick={() => onSelection(1)}
                      />
                    </td>
                    <td style={{ background: "#E8F4E3" }}>
                      <RadioButton
                        selected={selected === 2}
                        onClick={() => onSelection(2)}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {!props.backDisabled && (
            <div className="bottom-left-button">
              <BackButton buttonAction={props.back} />
            </div>
          )}

          <div className="bottom-right-button">
            <NextButton buttonAction={props.next} disable={selected === 0} />
          </div>
        </div>
      )}
    </>
  );
}

export default SurveyQuestion;
